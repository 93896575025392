// JavaScript Document

// Scripts written by Jacob Bearce @ Weblinx, Inc.

import { library, dom } from "@fortawesome/fontawesome-svg-core";

import { faAngleDown       as fasFaAngleDown       } from "@fortawesome/pro-solid-svg-icons/faAngleDown";
import { faAngleLeft       as fasFaAngleLeft       } from "@fortawesome/pro-solid-svg-icons/faAngleLeft";
import { faAngleRight      as fasFaAngleRight      } from "@fortawesome/pro-solid-svg-icons/faAngleRight";
import { faAnglesLeft      as fasFaAnglesLeft      } from "@fortawesome/pro-solid-svg-icons/faAnglesLeft";
import { faCalendar        as fasFaCalendar        } from "@fortawesome/pro-solid-svg-icons/faCalendar";
import { faCheck           as fasFaCheck           } from "@fortawesome/pro-solid-svg-icons/faCheck";
import { faCircle          as fasFaCircle          } from "@fortawesome/pro-solid-svg-icons/faCircle";
import { faClock           as fasFaClock           } from "@fortawesome/pro-solid-svg-icons/faClock";
import { faLocationPin     as fasFaLocationPin     } from "@fortawesome/pro-solid-svg-icons/faLocationPin";
import { faMagnifyingGlass as fasFaMagnifyingGlass } from "@fortawesome/pro-solid-svg-icons/faMagnifyingGlass";

/**
 * Add solid icons
 */
library.add(fasFaAngleDown, fasFaAngleLeft, fasFaAngleRight, fasFaAnglesLeft, fasFaCalendar, fasFaCheck, fasFaCircle, fasFaClock, fasFaLocationPin, fasFaMagnifyingGlass);

/**
 * Watch the DOM to insert icons
 */
dom.watch();
